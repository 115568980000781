(function ($, root, undefined) {

    $(function () {

        'use strict';
        
        // Global vars
        var stickyHide = 0;
        
        initNavLang();
        listenScroll();
        var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
      });
        /************
          FUNCTIONS
        *************/
        
        // Blocs fixed sur les ports
        function listenScroll() {
            var sticky = $('.sticky');
            var isSticky = false;
            var maxOffset = $('.bloc-header-ports').innerHeight() - 70;
            
            $(window).off('scroll', listenScrollEvent);
            $(window).on('scroll', listenScrollEvent);
            
            function listenScrollEvent() {
                if ($(window).scrollTop() >= maxOffset) {
                    if (!isSticky) {                        
                        setStickyElements();
                        isSticky = true;
                    }
                } else {
                    if (isSticky) {                        
                        unsetStickyElements();
                        isSticky = false;
                    }
                }
            }
            function setStickyElements() {
                if (($('body').hasClass('single-port')
                    && $(window).width() >= 1200)) {
                    sticky.each(function() {
                        $(this).removeAttr('style');

                        var offset = $(this).offset();
                        var top = offset.top + 5 - maxOffset;
                        var left = offset.left;
                        var width = $(this).innerWidth();

                        $(this).css({
                            position: 'fixed',
                            top: top,
                            left: left,
                            right: 'auto',
                            margin: 0,
                            width: width
                        });
                    });

                    var top = sticky.position().top;
                    var height = sticky.innerHeight();
                    var postWrapper = $('.post-wrapper');
                    var stopOffset = postWrapper.offset().top + postWrapper.outerHeight();

                    stickyHide = stopOffset - top - height - 60;

                    var blocEnPratique = postWrapper.find('.bloc-ressources');
                    var blocEnPratiqueHeight = 0;
                    if (blocEnPratique.length) {
                        blocEnPratiqueHeight = blocEnPratique.first().innerHeight() + 150;
                    }

                    stickyHide = stopOffset - top - height - blocEnPratiqueHeight - 60;

                    $(window).off('scroll', throttle(listenStickyScroll, 100, false, true));
                    $(window).on('scroll', throttle(listenStickyScroll, 100, false, true));
                }
            }
            function listenStickyScroll() {
                if ($(window).scrollTop() >= stickyHide) {
                    sticky.fadeOut(100);
                } else {
                    sticky.fadeIn(100);
                }
            }            
            function unsetStickyElements() {
                sticky.each(function() {
                    $(this).removeAttr('style');
                    $(window).off('scroll', throttle(listenStickyScroll, 100, false, true));
                });
            }
        }
        
        function initNavLang() {
            $('header .languages>.current').click(function() {
                $('#search-desktop').toggleClass('is-lang');
            });
            
            var search = $('.search-trigger');
            
            search.on('click', function() {
                $('header nav .languages').addClass('is-hidden');
            });
        }
        
        
        
        // Resize
        function resize() {
            listenScroll();
        }
        $(window).on('resize', throttle(resize, 100, false, true));
        
        // Throttle
        function throttle(func, wait, leading, trailing, context) {
            var ctx, args, result;
            var timeout = null;
            var previous = 0;
            var later = function() {
                previous = new Date;
                timeout = null;
                result = func.apply(ctx, args);
            };
            return function() {
                var now = new Date;
                if (!previous && !leading) previous = now;
                var remaining = wait - (now - previous);
                ctx = context || this;
                args = arguments;
                if (remaining <= 0) {
                    clearTimeout(timeout);
                    timeout = null;
                    previous = now;
                    result = func.apply(ctx, args);
                } else if (!timeout && trailing) {
                    timeout = setTimeout(later, remaining);
                }
                return result;
            };
        };
        

    });
})(jQuery, this);